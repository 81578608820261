<template>
  <div>
    <v-row
      justify="start"
      dense
      class="ml-2"
    >
      <v-col
        cols="12"
        md="5"
      >
        <v-text-field
          v-model="filterText"
          label="Pesquisar"
          placeholder="Gravação"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <v-btn
          @click="searchPrint"
          class="primary mr-2"
          icon
          dark
        >
          <v-icon small> fas fa-search </v-icon>
        </v-btn>
        <v-btn
          @click="toggleShowSelected"
          class="primary btn-arredondado"
          dark
        >
          <v-icon left> mdi-filter </v-icon>
          {{
            showOnlySelected
              ? 'Mostrar Todas as Gravações'
              : 'Somente Selecionadas'
          }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-subheader>Tipos de gravação</v-subheader>
        <v-card
          flat
          class="overflow-auto"
        >
          <v-list two-line>
            <v-list-item-group
              v-model="selectedPrintIds"
              multiple
            >
              <v-list-item
                v-for="item in filteredPrintTypes"
                :key="item.id"
                :value="item.id"
                active-class="primary--text text--accent-4"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="caption grey--text">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
function removeDuplicates(array) {
  const map = new Map()
  array.forEach((el) => map.set(String(el.id), el))
  return [...map.values()]
}

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    printTypes: [],
    originalPrintTypes: [],
    filterText: '',
    showOnlySelected: false,
    debounceTimeout: null,
  }),
  computed: {
    // Novo: Usa a propriedade 'prints' para trabalhar com os IDs dos prints
    selectedPrintIds: {
      get() {
        return this.product.prints && this.product.prints.length > 0
          ? this.product.prints.map((item) => String(item.id))
          : []
      },
      set(newIds) {
        // Atualiza o array de prints do produto com os objetos encontrados em printTypes
        this.product.prints = this.printTypes.filter((pt) =>
          newIds.includes(String(pt.id))
        )
      },
    },

    filteredPrintTypes() {
      let list = []
      if (this.filterText.trim() === '') {
        list = [...this.originalPrintTypes]
        if (this.product.prints) {
          this.product.prints.forEach((sel) => {
            if (!list.find((item) => String(item.id) === String(sel.id))) {
              list.push(sel)
            }
          })
        }
      } else {
        let filtered = this.printTypes.filter((item) =>
          item.name.toLowerCase().includes(this.filterText.toLowerCase())
        )
        if (this.product.prints) {
          this.product.prints.forEach((sel) => {
            if (!filtered.find((item) => String(item.id) === String(sel.id))) {
              filtered.push(sel)
            }
          })
        }
        list = filtered
      }

      if (this.showOnlySelected) {
        list = list.filter((item) =>
          this.selectedPrintIds.includes(String(item.id))
        )
      }
      return removeDuplicates(list)
    },
  },
  watch: {
    filterText() {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.searchPrint()
      }, 300)
    },
  },
  methods: {
    getPrint() {
      this.$api
        .get('product_prints')
        .then((res) => {
          // Assume que cada print tem 'id', 'name' e 'description'
          const data = res.data.data.map((item) => ({
            ...item,
            id: String(item.id),
          }))
          this.printTypes = data
          this.originalPrintTypes = data
        })
        .catch((err) => console.log(err))
    },

    searchPrint() {
      const searchTerm = this.filterText.trim()
      if (searchTerm === '') {
        this.printTypes = [...this.originalPrintTypes]
      } else {
        this.$api
          .post('product_prints/filter', { name: searchTerm })
          .then((res) => {
            const apiData = res.data.data.map((item) => ({
              ...item,
              id: String(item.id),
            }))
            const localData = this.originalPrintTypes.filter((item) =>
              item.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            this.printTypes = removeDuplicates([...apiData, ...localData])
          })
          .catch((err) => console.log(err))
      }
    },

    toggleShowSelected() {
      this.showOnlySelected = !this.showOnlySelected
    },

    removeItem(print) {
      if (this.product.prints) {
        this.product.prints = this.product.prints.filter(
          (item) => String(item.id) !== String(print.id)
        )
      }
    },

    addPrint(print, active) {
      if (active === true) {
        if (
          !this.product.prints ||
          !this.product.prints.find(
            (item) => String(item.id) === String(print.id)
          )
        ) {
          if (!this.product.prints) {
            this.product.prints = []
          }
          this.product.prints.push(print)
        }
      } else if (active === false) {
        this.removeItem(print)
      }
    },
  },
  created() {
    this.getPrint()
  },
}
</script>

<style scoped>
.btn-arredondado {
  border-radius: 20px;
}
</style>
